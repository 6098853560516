@tailwind base;
@tailwind components;
@tailwind utilities;

.h1 {
    @apply text-xl font-semibold text-brand-Black-300;
}

.h1-new {
    @apply text-lg font-semibold text-brand-Black-200;
}

.h2 {
    @apply text-base font-medium text-brand-Black-200;
}

.h3 {
    @apply text-sm font-medium;
}
.h4 {
    @apply text-sm font-normal;
}

.h5 {
    @apply text-base font-semibold;
}

.h6 {
    @apply text-base font-normal;
}

.h7 {
    @apply text-xs font-normal;
}

.card-h1 {
    @apply text-lg font-bold text-[#464F60];
}

.card-h2 {
    @apply text-base font-semibold;
}

.card-h3 {
    @apply text-sm font-normal text-brand-Gray-100;
}

.card-h4 {
    @apply text-xs leading-[18px] text-[#4A4A4A];
}

.card-description1 {
    @apply text-xs leading-[18px] text-gray-500;
}

.title-1 {
    @apply text-base font-bold leading-7 md:text-lg;
}

.text-active {
    @apply text-brand-Black-200;
}

.hover-transition-blue {
    @apply hover:bg-brand-Blue-100 hover:text-brand-Blue-200 hover:transition-all;
}

.hover-transition-gray {
    @apply hover:bg-brand-Gray-100 !important;
}

.gray-background {
    @apply rounded-lg bg-gray-100 px-2 py-1 text-black;
}

.custom-cron-input {
    @apply w-12 bg-transparent;
}

/* Chrome, Safari, Edge, Opera */
.custom-cron-input::-webkit-outer-spin-button,
.custom-cron-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.custom-cron-input[type='number'] {
    -moz-appearance: textfield;
}

.custom-cron-text {
    @apply flex justify-center text-center;
}

.focus-outline-primary {
    @apply focus:outline-none focus:outline-offset-0 focus:outline-brand-active-100/[.80];
}

.hover-transition-primary {
    @apply hover:shadow-md hover:transition-all;
}

.without-ring {
    @apply !ring-0 !ring-offset-0  focus:ring-0 focus:ring-offset-0;
}

.without-focus-visible {
    @apply focus-visible:!outline-none focus-visible:!ring-0 focus-visible:!ring-offset-0 active:!border-none;
}

.pagination-btn-position {
    @apply absolute bottom-4 right-5 md:bottom-8 md:right-10;
}

.no-wrap-truncate {
    @apply overflow-hidden text-ellipsis whitespace-nowrap;
}
